import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "template", "mapping", "crmFieldSelect"]
  static values = {
    dataSourceFields: Object
  }

  connect() {
    this.updateAllCrmFields()
  }

  updateCrmFields(event) {
    const mapping = event.target.closest('[data-field-mappings-target="mapping"]')
    const crmObjectSelect = event.target
    const crmFieldSelect = mapping.querySelector('[data-field-mappings-target="crmFieldSelect"]')
    const selectedObject = crmObjectSelect.value

    this.populateCrmFields(crmFieldSelect, selectedObject)
  }

  updateAllCrmFields() {
    this.mappingTargets.forEach(mapping => {
      const crmObjectSelect = mapping.querySelector('.crm-object-select')
      const crmFieldSelect = mapping.querySelector('[data-field-mappings-target="crmFieldSelect"]')
      if (crmObjectSelect && crmFieldSelect) {
        this.populateCrmFields(crmFieldSelect, crmObjectSelect.value)
      }
    })
  }

  populateCrmFields(crmFieldSelect, selectedObject) {
    const currentValue = crmFieldSelect.dataset.selectedValue || crmFieldSelect.value

    if (selectedObject && this.dataSourceFieldsValue && this.dataSourceFieldsValue[selectedObject]) {
      crmFieldSelect.innerHTML = '<option value="">Select CRM Field</option>'
      this.dataSourceFieldsValue[selectedObject].forEach((field) => {
        const option = new Option(field.title || field.name, field.name)
        if (field.name === currentValue) {
          option.selected = true
        }
        crmFieldSelect.add(option)
      })
    }

    // If the current value wasn't found in the new options, select the first option
    if (crmFieldSelect.value === "") {
      crmFieldSelect.selectedIndex = 0
    }
  }
}
